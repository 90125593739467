import {Component, EventEmitter, Output} from '@angular/core';
import {FileExtension} from 'src/app/enums/core/file-extension.enum';
import {InputType} from 'src/app/enums/core/input-type.enum';
import {ProfileFacade} from 'src/app/facades/profile.facade';
import {FileUploadEvent} from 'src/app/interfaces/core/file-upload-event.interface';
import {FormUtilsModule} from 'src/app/modules/form-utils/form-utils.module';

const RAD_MODULES = [
    FormUtilsModule
];

@Component({
    selector: 'app-zus-certificate-upload',
    standalone: true,
    imports: [
        ...RAD_MODULES
    ],
    templateUrl: './zus-certificate-upload.component.html'
})
export class ZusCertificateUploadComponent {
    @Output() public zusCertificateUploaded: EventEmitter<void> = new EventEmitter<void>();

    protected readonly FileExtension = FileExtension;
    protected readonly InputType = InputType;

    constructor(
        protected profileFacade: ProfileFacade
    ) {
    }

    protected uploader = ($event: FileUploadEvent) => this.profileFacade.uploadZusCertificate($event);

    protected onAllUploadsSuccessfulComplete() {
        this.zusCertificateUploaded.emit();
    }
}